<template>
  <layout-default>
    <div class="container about-features mt-5" v-if="appIsLasAmericas">
      <SectionFeatures />
    </div>
    <div class="container masonry" style="margin-top: 35px">
      <div class="row">
        <div v-if="type" class="col-12 mb-4">
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="@/assets/images/banners/banner1a.jpg"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/images/banners/banner2a.jpg"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/images/banners/banner3a.jpg"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <MasonryProduct
            class="mb-5"
            :title="'Remates'"
            :height="400"
            :imageSrc="getImageAuction"
            viewMoreLocation="/auctions"
          />
        </div>
        <div class="col-md-6 col-sm-12">
          <MasonryProduct
            class="mb-5"
            :title="'Venta Directa'"
            :height="400"
            :imageSrc="getImageDirectSale"
            viewMoreLocation="/direct-sale"
          />
        </div>
      </div>
      <heading
        v-if="appIsLasAmericas"
        :title="'¡BIENVENIDOS A REMATES LAS AMÉRICAS!'"
        class="my-3"
      />
      <p class="text-center my-3 text-home" v-if="appIsLasAmericas">
        Somos una corporación internacional, dedicada a la Intermediación de
        <b>Bienes Muebles e Inmuebles</b>, mediante nuestros canales de ventas,
        los cuales son: Subastas Públicas. Presenciales y Online (en tiempo
        real), Licitaciones públicas, Ventas Directas.
      </p>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "../../components/organisms/LayoutDefault/LayoutDefault.vue";
import SectionFeatures from "@/components/organisms/Section/SectionFeatures.vue";
import MasonryProduct from "../../components/molecules/Banner/BannerMasonry.vue";
import Heading from "@/components/atoms/SectionHeading/Heading.vue";
export default {
  name: "Home",
  data() {
    return {
      type: process.env.VUE_APP_NAME == "casalira" ? false : true,
    };
  },
  beforeCreate() {
    document.title =
      process.env.VUE_APP_NAME == "casalira"
        ? "Casa Lira - Remates Online"
        : "Remates Las Americas";
  },
  components: {
    LayoutDefault,
    SectionFeatures,
    MasonryProduct,
    Heading,
  },
  computed: {
    appIsLasAmericas() {
      return this.$store.getters.appIsLasAmericas;
    },
    getImageAuction() {
      return this.appIsLasAmericas
        ? "images/home/remate.jpg"
        : "images/home/forest.jpeg";
    },
    getImageDirectSale() {
      return this.appIsLasAmericas
        ? "images/home/ventadirectalasamericas.jpg"
        : "images/home/venta-directa.jpeg";
    },
  },
};
</script>
<style scoped="scoped">
.text-home {
  font-size: 20px;
}

.text-list {
  text-decoration: none !important;
  font-size: 18px;
  color: black;
  font-weight: bold;
  list-style-type: none;
}

.features,
.latest-auctions,
.latest-auctions-category,
.masonry,
.contact,
.news,
.brands {
  margin-bottom: 90px;
}
.latest-auctions-category {
  padding: 0 21px;
}
.brands {
  background-color: #f9f9f9;
  padding: 70px 0px;
}
:deep(.Counter .col-3) {
  padding: 8px 13px !important;
}
@media (max-width: 767.98px) {
  :deep(.latest-auctions-category) {
    padding: 0px !important;
  }
  :deep(.mas) {
    height: 100% !important;
  }

  .brands {
    padding: 70px 0px;
  }
  :deep(.Counter .col-3) {
    padding: 7px 0px !important;
  }
}
.carousel-control-prev {
  justify-content: start;
}
.carousel-control-next {
  justify-content: end;
}
</style>
